.App {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text color */
}

section {
  margin-bottom: 40px;
}

button {
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #1e88e5; /* Blue button background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #1565c0; /* Darker blue on hover */
}

textarea {
  padding: 10px;
  font-size: 14px;
  background-color: #1e1e1e; /* Dark textarea background */
  color: #e0e0e0; /* Light text */
  border: 1px solid #444; /* Subtle border */
  border-radius: 4px;
}

video {
  border: 1px solid #444; /* Subtle border for dark theme */
  border-radius: 4px;
  background-color: #000; /* Ensure dark background for video */
}
